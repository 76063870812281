import './App.scss';
import { useState } from 'react';
import axios from 'axios';

function App() {
  const [url, setUrl] = useState('https://');
  const [host, setHost] = useState('zoe');
  const [language, setLanguage] = useState('UK');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [latestStatus, setLatestStatus] = useState('');
  let interval = 0;
  let token = '';

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleHostChange = (host) => {
    setHost(host);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  /**
   * Create a new render
   */
  const handleRender = async () => {
    setLoading(true);
    setVideoUrl('');
    setErrorMessage('');

    let result = await axios.post("https://engagement-newscaster-api-acc.lwdev.nl/api/save", {
      url,
      host,
      language
    });
    let data = result.data.data.data;
    token = data.token;

    interval = setInterval(handleStatus, 1000)
  }

  /** 
   * Get status
   */
  const handleStatus = async () => {
    let result = await axios.post("https://engagement-newscaster-api-acc.lwdev.nl/api/getStatus", { token: token });
    let data = result.data.data.data;
    if (data.status === 2) {
      setVideoUrl(data.outputUrl);
      setLatestStatus(0);
      clearInterval(interval);
      setLoading(false);
    }
    if (data.status === -1) {
      setLoading(false);
      setErrorMessage('An error occured when rendering the video')
    }
    else {
      setProgress(data.progress);
      setLatestStatus(data.statusMessages[data.statusMessages.length - 1])
    }
  }

  return (
    <div className="App">
      <div className="app-container">


        <div className="app-area__sidebar">
          <div className="logo"></div>
          <div className="app-area__sidebar__introduction">
            Generate a video based on your article.
          </div>

          <div className="app-area__sidebar__form">
            <div className="app-area__sidebar__form__field">
              <div className="app-area__sidebar__form__field__label">
                Article URL
              </div>
              <input type="text" placeholder="Article URL"
                value={url}
                onChange={handleUrlChange} />
            </div>

            <div className="app-area__sidebar__form__field">
              <div className="app-area__sidebar__form__field__label">
                Language
              </div>
              <select
                value={language}
                onChange={handleLanguageChange}>
                <option value="NL">Dutch</option>
                <option value="BE">Belgian</option>
                <option value="UK">English (UK)</option>
                <option value="US">English (US)</option>
                <option value="FR">French</option>
                <option value="DE">German</option>
              </select>
            </div>
          </div>

          <div className="app-area__sidebar__form__field">
            <div className="app-area__sidebar__form__field__label">
              Choose your host
            </div>
            <div className="app-area__sidebar__form__field__hosts">
              <div onClick={() => handleHostChange('zoe')} className={"app-area__sidebar__form__field__hosts__host app-area__sidebar__form__field__hosts__host1 " + (host === 'zoe' ? 'app-area__sidebar__form__field__hosts__host--active' : '')} >
                <div className="app-area__sidebar__form__field__hosts__host__image image1">

                </div>
                <div className="app-area__sidebar__form__field__hosts__host__name">
                  Zoe
                </div>
              </div>

              <div onClick={() => handleHostChange('mike')} className={"app-area__sidebar__form__field__hosts__host app-area__sidebar__form__field__hosts__host2 " + (host === 'mike' ? 'app-area__sidebar__form__field__hosts__host--active' : '')} >
                <div className="app-area__sidebar__form__field__hosts__host__image image2">

                </div>
                <div className="app-area__sidebar__form__field__hosts__host__name">
                  Mike
                </div>
              </div>

              {(language === 'UK' || language === 'US') && (
                <div onClick={() => handleHostChange('david')} className={"app-area__sidebar__form__field__hosts__host app-area__sidebar__form__field__hosts__host3 " + (host === 'david' ? 'app-area__sidebar__form__field__hosts__host--active' : '')} >
                  <div className="app-area__sidebar__form__field__hosts__host__image image3">

                  </div>
                  <div className="app-area__sidebar__form__field__hosts__host__name">
                    David A
                  </div>
                </div>
              )}

            </div>
          </div>
          {loading && progress === 0 && (<div className="app-area__sidebar__loading">
            <div class="loader"></div>
          </div>)}
          {loading && progress > 0 && (<div className="app-area__sidebar__loading__bar">
            <div class="progress-bar">
              <div class="progress-bar__fill" style={{ width: progress + '%' }}>{progress}%</div>
            </div>
          </div>)}

          {errorMessage && (<div className="app-area__sidebar__error">{errorMessage}</div>)}

          {!loading && <button onClick={handleRender} className="app-area__sidebar__button">GENERATE</button>}

          <div className="livewall">
            <a href="https://www.livewall.nl" target="_blank" rel="noreferrer">
            </a>
          </div>
        </div>
        <div className="app-area__video">
          <div className="app-area__video__holder">
            {latestStatus && (<div className="app-area__video__status">
              {latestStatus}
            </div>)}

            {videoUrl && (
              <video controls autoPlay src={videoUrl}>
              </video>
            )}
          </div>

        </div>
      </div>

    </div >
  );
}

export default App;
